import React, { Component } from 'react'
import ReactGA from 'react-ga';
import '../styles/Awards.css';

export default class Awards extends Component {
    componentDidMount() {
        document.title = "Awards - Willingham's Barbecue | Redondo Beach, California";
        ReactGA.initialize('UA-132822781-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return (
            <section className="content-section-awards">
				<div className="container container--medium container--widened">
					<div className="content-block">
                        <div className="award-lists">
                            <div>
                            <h2>The Awards</h2>
                            <ul className="awards-ul">
                                <li><span>1983 First Place Ribs</span> <span>Memphis In May World Championship</span></li>
                                <li><span>1983 Grand Champion</span> <span>Memphis In May World Championship</span></li>
                                <li><span>1984 First Place Ribs</span> <span>Memphis In May World Championship</span></li>
                                <li><span>1984 Grand Champion</span> <span>Memphis In May World Championship</span></li>
                                <li><span>1984 First Place Beef, Pork, & Lamb</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1984 Grand Champion</span> <span>American Royal, Kansas City, Missouri</span></li>

                                <li><span>1984 Grand Champion</span> <span>World’s Best Ribs, Richmond, VA</span></li>
                                <li><span>1984 Grand Champion</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1984 Grand Champion</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1984 Grand Champion</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1984 Grand Champion</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1984 Grand Champion</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1984 Grand Champion</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1984 Grand Champion</span> <span>American Royal, Kansas City, Missouri</span></li>

                                <li><span>1987 Grand Champion</span> <span>World’s Best Ribs, Richmond, VA</span></li>
                                <li><span>1989 “THE HICKORY SWITCH AWARD”</span> <span>Best “Que” in Memphis, TN</span></li>
                                <li><span>1989 First Place Lamb</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1990 First Place Whole Hog</span>	<span>Memphis In May World Championship</span></li>
                                <li><span>1990 Grand Champion Ribs $25,000</span> <span>National Rib Cookoff, Cleveland, Ohio</span></li>
                                <li><span>1990 First Place Brisket Shoulder and Wings</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1991 COOKING TEAM OF THE YEAR</span>	<span>Memphis In May World Championship</span></li>
                                <li><span>1991 Grand Champion Ribs $25,000</span> <span>National Rib Cookoff, Cleveland, Ohio</span></li>
                                <li><span>1991 First Place Brisket Shoulder and Brisket</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1991 Grand Champion</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1991 First Place Whole Hog & Shoulder</span> <span>City Fest ’91, Tuscaloosa, Alabama</span></li>
                                <li><span>1991 First Place Whole Hog & Ribs</span>	<span>Christmas on the River, Demopolis, Alabama</span></li>
                                <li><span>1991 Grand Champion</span> <span>Christmas on the River, Demopolis, Alabama</span></li>
                                <li><span>1991 First Place Brisket, Hog, & Shoulder</span> <span>Castle BBQ Fest, Orlando, Florida</span></li>
                                <li><span>1992 COOKING TEAM OF THE YEAR</span>	<span>Memphis In May World Championship</span></li>
                                <li><span>1992 First Piace Anything But Pork</span>	<span>Memphis In May World Championship</span></li>
                                <li><span>1992 First Piace “Skinner’s Pasta”</span>	<span>Memphis In May World Championship</span></li>
                                <li><span>1992 Grand Champion People’s Choice Ribs </span> <span>Star Trib, Magis, MS</span></li>
                                <li><span>1992 Grand Champion Ribs People’s Choice</span> <span>BBQ Festival, Norfolk, Virginia</span></li>
                                <li><span>1992 First Piace Poultry, Shoulder & Ribs</span>	<span>Great Yankee BBQ, Boston, Mass.</span></li>
                                <li><span>1992 Grand Champion People’s Choice</span> <span>Great Yankee BBQ, Boston, Mass.</span></li>
                                <li><span>1992 First Piace Lamb & Brisket</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1992 BEST SAUCE ON THE PLANET</span>	<span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1992 Grand Champion Reserve</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1992 Grand Champion Chicken</span> <span>American Pitmasters BBQ, Boston, Mass.</span></li>
                                <li><span>1993 BEST COOKER IN THE WORLD</span>	<span>Memphis In May World Championship</span></li>
                                <li><span>1994 Grand Champion</span> <span>Miss. State Championship, Lexington, Miss.</span></li>
                                <li><span>1994 Grand Champion Reserve</span> <span>North Carolina State Championship, Tryon, N.C.</span></li>
                                <li><span>1994 First Piace Beef Briskets</span>	<span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1994 First Piace Farmland Ribs</span>	<span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1994 Grand Champion Farmland Ribs</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1994 Best Cooker in the World</span> <span>Memphis in May</span></li>
                                <li><span>1995 First Place Hot Sauce</span>	<span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1995 First Place Mild Vinegar Sauce</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1995 First Place Cajun Hot Sauce</span> <span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>1995 The Quinn Award for Bar-B-Que Excellence</span>	<span>Jonesboro, Ark.</span></li>
                                <li><span>1995 First Place Beef/Seafood Pasta</span> <span>Anything But Pork, Southhaven, Miss.</span></li>
                                <li><span>1995 Grand Champion</span> <span>Anything But Pork, Southhaven, Miss.</span></li>
                                <li><span>1995 The SONY’S Best Barbeque in America</span> <span>Long Island, N.Y.</span></li>
                                <li><span>1996 First Place Ribs</span>	<span>St. Jude/VFW BBQ Festival, Whitehaven, Tenn.</span></li>
                                <li><span>1996 Grand Champion</span> <span>St. Jude/VFW BBQ Festival, Whitehaven, Tenn.</span></li>
                                <li><span>1996 Best of Sauce</span>	<span>Arkansas BBQ Championship, Batesville, Ark.</span></li>
                                <li><span>1996 PEOPLE’S CHOICE</span> <span>Memphis In May World Championship</span></li>
                                <li><span>1996 Best Que Offshore</span>	<span>Freeport McMoran Invitational, Gulf of Mexico</span></li>
                                <li><span>1997 Featured on “Prime Time Live” at Memphis in May</span> <span>New York, N.Y.</span></li>
                                <li><span>1997 Featured on “At Home on the Grill” PBS Special</span> <span>Memphis, Tenn.</span></li>
                                <li><span>1997 People’s Choice</span> <span>Smithsonian July 4th Invitational, Washington, D.C.</span></li>
                                <li><span>1997 Champions</span> <span>Mississippi State BBQ, Ashland, Miss.</span></li>
                                <li><span>1997 Best Cooker in the World</span> <span>Memphis in May</span></li>
                                <li><span>1998 Grand Champion (R)</span> <span>North Carolina State Championship, Tryon, N.C.</span></li>
                                <li><span>1998 Featured on “Home Cooking with Amy Coleman”</span> <span>Vancouver, B.C.</span></li>
                                <li><span>1998 Featured on NBC Today Show’s BBQ Special</span> <span>New York, N.Y.</span></li>
                                <li><span>1998 Grand Champion, First Place Ribs</span> <span>Prim Valley BBQ Championship, Prim, N.Y.</span></li>
                                <li><span>1998 Champions</span> <span>Mississippi State BBQ, Ashland, Miss.</span></li>
                                <li><span>1998 Best Cooker in the World</span> <span>Memphis in May</span></li>
                                <li><span>1999 Grand Champion Best Ribs Overall</span>	<span>Memphis Flyer, Memphis, Tenn.</span></li>
                                <li><span>1999 Featured on Food Network’s with Al Roker</span>	<span>New York, N.Y.</span></li>
                                <li><span>1999 Champions</span> <span>Mississippi State BBQ, Ashland, Miss.</span></li>
                                <li><span>2000 Frito Lay’s / Cross Creek Ranch</span> <span>BBQ & Barn Dance, McKinney, Tex.</span></li>
                                <li><span>2000 Best Sauce on the Planet</span>	<span>American Royal, Kansas City, Missouri</span></li>
                                <li><span>2001 Featured on Food Network’s “Booby Flay TV Show”</span> <span>New York, N.Y.</span></li>
                                <li><span>2001 Featured as “Best BBQ in Memphis, TN”</span>	<span>Chili Pepper Magazine</span></li>
                                <li><span>2001 First Place Chicken</span> <span>Big Brother, Big Sister Charity BBQ, Lafayette, La.</span></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
