import React from 'react';
import { BrowserRouter , Route } from 'react-router-dom';
import './styles/App.css';

import NavBar from './components/Navbar';
import Footer from './components/Footer';

import About from './pages/About';
import Awards from './pages/Awards';
import Menu from './pages/Menu';

function App() {
  return (
      <>
          <BrowserRouter>
          <NavBar />
          <Route exact path="/" component={Menu}/>
          <Route path="/about" component={About}/>
          <Route path="/awards" component={Awards}/>
          <Route path="/menu" component={Menu}/>
        </BrowserRouter>
        <Footer />
      </>
  );
}

export default App;
