import React, { Component } from 'react';
import ReactGA from 'react-ga';
import '../styles/About.css';

export default class About extends Component {
  componentDidMount() {
		document.title = "About - Willingham's Barbecue | Redondo Beach, California";
		ReactGA.initialize('UA-132822781-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
	render() {
		return (
			<section className="content-section-about">
				<div className="container container--medium container--widened">
					<div className="content-block">
						<div className="about-lists">
							<div>
								<h2>The Legend</h2>
								<h3>From Memphis to the World</h3>
								<img src={process.env.PUBLIC_URL + '/img/john-willingham.jpg'} alt="" />
								<span className="john-special">J&nbsp;</span>
								<p>
									ohn Willingham was famous for barbecue and the barbecue sauces and rubs he developed
									to enhance his great cooking techniques. A creative entrepreneur and inveterate
									tinkerer, he spent a big chunk of his long life in search of a more perfect way to
									barbecue. Forty years ago, he designed the cooker you see above – the W’ham Turbo
									Cooker, which kept the smoke away from the meat but sealed the flavor in. For the
									next decade, he worked on improving the cooker, the techniques to make the most of
									it, along the way developing a range of barbecue seasonings and sauces to enhance
									his more perfect meats.
								</p>
								<h3>"The First Family of Memphis Barbecue"</h3>
								<p>
									In 1983, triumph! W’ham ribs wowed the judges in the{' '}
									<a href="https://www.memphisinmay.org/events/world-championship-barbecue-cooking-contest/">
										Memphis-in-May International Barbecue Festival
									</a>. Ribs cooked by his “River City Rooters” team placed first, and the team was
									crowned “The Overall World’s Barbecue Grand Champion.” Two years later, the team
									repeated that success. In 1985, John proved it wasn’t just a Memphis thing. The team
									placed first in Kansas City’s{' '}
									<a href="https://www.americanroyal.com/events_detail.aspx?id=129">
										American Royal
									</a>{' '}
									barbecue contest (considered by some the grand pageant of barbecue, but we like all
									the competitions!) for its pork, its beef and its lamb, and was again crowned
									overall grand champion. Those were the first in a long string of victories,
									culminating in John’s entry into the American Royal’s Hall of Fame, barbecue’s top
									honor. John’s cooking, seasoning, and barbecue sauces continue to win top awards
									every year! Alan Richman, host of the Travel Channel’s Fandemonium, called
									Willingham’s “the first family of Memphis barbecue,” in his report on 2013’s Memphis
									in May festival.
								</p>
								<h3>The Legacy: Great Barbecue Sauces and Rubs</h3>
								<p>
									People who know barbecue know Willingham’s. Nathan Myrvold, a former Microsoft
									executive now also known for his passion to explore and document the science and art
									of cooking, was so impressed by John’s barbecue that he joined the Willingham’s team
									in the 2012 Memphis contest. “My barbecue did get better,” Myrvold blogged. “Maybe
									not as good as John’s, but I’m still working on it.” Today Willingham’s barbecue
									sauces and flavorings are favorites of professional and amateur cooks across the
									country.{' '}
								</p>
								<h3>The Champion</h3>
								<img src={process.env.PUBLIC_URL + '/img/AmerRoyalHallofFame.jpg'} alt="" />
								<p>
									John Willingham knew how to make great barbecue, and it was a passion throughout his
									life.
								</p>
								<p>
									"John Willingham was a true legend of barbecue," said the organizers of the Memphis
									in May Festival, "winning awards everywhere he cooked, and winning friends at every
									stop with his own brand of wisdom, humor and kindness." The contest organizers
									termed him “the most acclaimed barbecue chef in the world.”
								</p>
								<p>
									John (second from left in the photo below, along with other inductees in 2012) was
									inducted into the American Royal’s Hall of Fame, barbecue’s top honor. The award
									recognizes contributions to the advancement of barbecue by individuals in the
									competitive, business and entertainment communities. John, a former St. Louis
									Cardinal baseball player, invented more than just a better way to cook barbecue.
								</p>
								<h3>The Cooker</h3>
								<img
									src={process.env.PUBLIC_URL + '/img/cooker.jpg'}
									alt=""
									width="500px"
                  height="900px"
                  className="cooker-img"
								/>
								<p>
									John Willingham created his W’ham Turbo Cooker nearly thirty years ago, and made his
									mark in culinary history. No less an authority than Nathan Myrvold, the former
									Microsoft technologist known for his painstaking research into cooking techniques,
									credits the W’ham he bought from John for the best ribs he’s ever made.
								</p>
								<p>
									The cooker minimizes the smoke but maximizes the taste, keeping the fire away from
									the meat while cooking at low temperature in the indirect fashion that defines
									classic barbecue. The basic version (60 inches wide, 35 inches deep and 77 inches
									tall) is built of durable, sanitary stainless steel – to last and last. With it, you
									can cook 32 slabs of ribs, 100 lbs. of butts or brisket, or 16 burgers. Should the
									need arise, you can cook 528 hot dogs in an hour. Willingham’s has also developed a
									commercial version of the cooker that can handle up to 100 slabs of ribs. The cooker
									has won fans all over the country, and has been the foundation of Willingham’s
									amazing record in barbecue competitions (along with, of course, our rubs and
									sauces).
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
