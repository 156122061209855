import React, { Component } from 'react';
import '../styles/Footer.css';

export default class Footer extends Component {
	render() {
		return (
			<footer className="footer">
				<div className="column">
					<div className="row">
						<div className="column">
							<ul className="social-media-ul">
								<li>
									<a href="https://www.instagram.com/willinghamsbbq/">
										<img
											src={process.env.PUBLIC_URL + '/img/instagram.svg'}
											alt="instagram"
										/>&nbsp;&nbsp;Instagram
									</a>
								</li>
								<li>
									<a href="https://www.facebook.com/willinghambbq">
										<img
											src={process.env.PUBLIC_URL + '/img/facebook.svg'}
											alt="facebook"
										/>&nbsp;&nbsp;Facebook
									</a>
								</li>
							</ul>
						</div>
						<div className="column-2">
							<ul className="social-media-ul">
								<li id="yelp">
									<a href="https://www.yelp.com/biz/willinghams-world-champion-bbq-redondo-beach-2">
										<img src={process.env.PUBLIC_URL + '/img/yelp.svg'} alt="yelp" />&nbsp;&nbsp;Yelp
									</a>
								</li>
								<li>
									<a href="mailto:willinghamsbbq@gmail.com">
										<img
											src={process.env.PUBLIC_URL + '/img/postbox.svg'}
											alt="contact-email"
										/>&nbsp;&nbsp;Contact
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="column">
							<ul className="info-ul">
								<li className="address"><a href="https://www.google.com/maps/place/Willingham's+World+Champion+BBQ/@33.8350024,-118.3855434,15z/data=!4m5!3m4!1s0x0:0x98bbaf2728ef6fb5!8m2!3d33.8350024!4d-118.3855434">443 S. PCH Redondo Beach, CA 90277</a></li>
								<li>(424) 390 - 4300</li>
								<br />
								<li><span className="alpha-dates">Mondays & Tuesdays&nbsp;&nbsp;</span> Closed</li>
								<li><span className="alpha-dates">Wed-Sun&nbsp;</span> 11am - 5pm</li>
								<li className="copy-right-li"><span className="alpha-dates"><a href="https://www.freeprivacypolicy.com/live/03594b70-ceba-4c8e-9e53-f7aa0b467e0b">privacy policy</a></span></li>
								<li className="copy-right-li"><span className="copy-right-span">&copy; 2024 Willingham's Barbecue</span></li>
							</ul>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
