import React, { Component } from 'react';
import ReactGA from 'react-ga';
import '../styles/Menu.css'

export default class Menu extends Component {
	componentDidMount() {
		document.title = "Menu - Willingham's Barbecue | Redondo Beach, California";
		ReactGA.initialize('UA-132822781-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
  	}

	render() {
		return (
            <section className="content-section">
				<div className="container container--medium container--widened">
					<div className="content-block">
						<div className="menu-lists">
							<div>
								<h2>BY THE POUND</h2>
								<ul>
									<li>
										<span>Brisket</span> <span>35</span>
									</li>
									<li>
										<span>Chopped Brisket</span> <span>31</span>
									</li>
									<li>
										<span>Pulled Pork</span> <span>27</span>
									</li>
									<li>
										<span>Pork Ribs</span> <span>28</span>
									</li>
                                    <li>
										<span>Sausage</span>{' '}
										<span>
											23 <small>&nbsp;(6 / link)</small>
										</span>
									</li>

                                    
									<li>
										<span>Turkey</span> <span>28 <small>&nbsp;(Fri, Sat, Sun Only)</small></span>
									</li>
									<li>
										<span>
											Beef Ribs 
										</span>
										<span>31 <small>&nbsp;(Fri, Sat, Sun Only)</small></span>
									</li>
								</ul>
							</div>
							<div>
								<h2>PLATES <span><small>&nbsp;&nbsp;&nbsp;Served with 2 Sides & Bread</small></span></h2>
								<ul>
									<li>
										<span>Brisket</span> <span>25</span>
									</li>
									<li>
										<span>Pulled Pork</span> <span>22</span>
									</li>
									<li>
										<span>Turkey Breast</span> <span>24</span>
									</li>
									<li>
										<span>Pork Ribs</span> <span>24</span>
									</li>
                                    <li>
										<span>Sausage</span> <span>19</span>
									</li>
                                    <li>
                                        <span>Choice of 2 Meats</span> <span>27</span>
                                    </li>
									<li>
										<span>Brisket Double Up</span> <span>29</span>
									</li>
								</ul>
							</div>
							<div>
								<h2 className="sandwiches-h2">SANDWICHES <span><small>&nbsp;&nbsp;&nbsp;Served with 1 Side</small></span> </h2>
								<div>
									<ul>
										<li>
											<span>Brisket</span> <span>16</span>
										</li>
										<li>
											<span>Chopped Brisket</span> <span>14</span>
										</li>
										<li>
											<span>Pulled Pork</span> <span>14</span>
										</li>
										<li>
											<span>Turkey</span> <span>15</span>
										</li>
										<li>
											<span>Sausage</span> <span>11</span>
										</li>
										<li>
											<span>Cali Dude</span> <span>14</span>
										</li>
									</ul>
								</div>
							</div>
							<div>
								<h2>SIDES</h2>
								<div className="combined">
									<ul>
										<li>
											<span>Potato Salad</span>
										</li>
										<li>
											<span>Cole Slaw</span>
										</li>
										<li>
											<span>Baked Beans</span>
										</li>
										<li>
											<span>Collard Greens</span>
										</li>
										<li>
											<span>Cream Corn</span>
										</li>
									</ul>
									<ul>
										<li>
											<span>
												<small className="sides">Single</small>
											</span>{' '}
											<span>4.5</span>
										</li>
										<li>
											<span>
												<small className="sides">Pint</small>
											</span>{' '}
											<span>9</span>
										</li>
										<li>
											<span>
												<small className="sides">Quart</small>
											</span>{' '}
											<span>17</span>
										</li>
									</ul>
								</div>
							</div>
							<div>
								<h2>CHILI <span><small>&nbsp;&nbsp;&nbsp;House-made Beanless Meat Chili</small></span></h2>
								<ul>
									<li>
										<span>Single</span> <span>7.5</span>
									</li>
									<li>
										<span>Pint</span> <span>15</span>
									</li>
									<li>
										<span>Quart</span> <span>29</span>
									</li>
								</ul>
							</div>
							<div>
								<h2>DRINKS</h2>
								<ul>
                                    <li>
										<span>Bottle Soda</span> <span>3.25</span>
									</li>
                                    <li>
										<span>Mexican Coke</span> <span>3</span>
									</li>
                                    <li>
										<span>Bottled Water</span> <span>2.5</span>
									</li>
									<li>
										<span>Can Soda</span> <span>2</span>
									</li>
								</ul>
							</div>
							<div>
								<h2>BEERS</h2>
								<ul>
									<li>
										<span>805</span> <span>4.5 </span>
									</li>
									<li>
										<span>Lagunitas IPA</span> <span>4.5 </span>
									</li>
									<li>
										<span>Stella Artois</span> <span>4.5 </span>
									</li>
									<li>
										<span>Budweiser</span> <span>3.5 </span>
									</li>
									<li>
										<span>Coors Light</span> <span>3.5 </span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
                </section>
		);
	}
}
