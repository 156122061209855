import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/Navbar.css';

export default class Navbar extends Component {
	render() {
		return (
			<div className="navbar">
				<div className="navbar-brand">
					<NavLink exact activeClassName="activeRoute" to="/menu">
						<img
							src={process.env.PUBLIC_URL + '/img/logo.png'}
							width="120px"
							height="95px"
							id="logo"
							alt="wham-logo"
						/>
					</NavLink>
				</div>
				<div className="navbar-menu">
					<NavLink className="navlink" exact activeClassName="active" to="/menu" activeStyle={{borderTopColor: "#EEB02E"}}>
						MENU
					</NavLink>
					<NavLink className="navlink" exact activeClassName="active" to="/about" activeStyle={{borderTopColor: "#EEB02E"}}>
						ABOUT
					</NavLink>
					<NavLink className="navlink" exact activeClassName="active" to="/awards" activeStyle={{borderTopColor: "#EEB02E"}}>
						AWARDS
					</NavLink>
					<a className='chownow-order-online' href='https://order.online/store/willinghams-world-champion-bbq-439323'>Order Online</a>
				</div>
			</div>
		);
	}
}
